var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center static"},[_c('router-link',{staticClass:"inline-flex items-center absolute",attrs:{"to":{ name: 'tenant-home' }}},[_c('i',{staticClass:"tmlps-i-arrow-left text-5xl"}),_c('img',{staticClass:"h-8 w-8 border ml-2",attrs:{"src":_vm.tenant.logo_url ? _vm.tenant.logo_url : (_vm.publicPath + "img/logo-placeholder.png"),"alt":_vm.tenant.name}}),_c('img',{staticClass:"h-8 w-8 border ml-2",attrs:{"src":_vm.project.logo_url ? _vm.project.logo_url : (_vm.publicPath + "img/logo-placeholder.png"),"alt":_vm.project.name}}),_c('span',{staticClass:"font-bold ml-2"},[_vm._v(_vm._s(_vm.project.name))])]),_c('nav',{staticClass:"flex-grow py-3"},[_c('ul',{staticClass:"flex justify-center space-x-16"},[_c('li',[_c('router-link',{attrs:{"to":{ name: 'project-home' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"block",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"px-2",class:{ 'font-bold': isExactActive }},[_vm._v("Cámaras")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(isExactActive),expression:"isExactActive"}],staticClass:"h-1 bg-primary-500 rounded-t-sm mt-px"})])]}}])})],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'project-users' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"block",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"px-2",class:{ 'font-bold': isExactActive }},[_vm._v("Usuarios")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(isExactActive),expression:"isExactActive"}],staticClass:"h-1 bg-primary-500 rounded-t-sm mt-px"})])]}}])})],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'project-reports' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"block",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"px-2",class:{ 'font-bold': isExactActive }},[_vm._v("Reportes")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(isExactActive),expression:"isExactActive"}],staticClass:"h-1 bg-primary-500 rounded-t-sm mt-px"})])]}}])})],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'project-information' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"block",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"px-2",class:{ 'font-bold': isExactActive }},[_vm._v("Información")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(isExactActive),expression:"isExactActive"}],staticClass:"h-1 bg-primary-500 rounded-t-sm mt-px"})])]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }