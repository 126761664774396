<template>
  <AppLayout>
    <template v-slot:sub-nav>
      <SubNav>
        <project-sub-nav :tenant="tenant.current" :project="project.current" />
      </SubNav>
    </template>

    <router-view />
  </AppLayout>
</template>

<script>
import store from '@/store';
import { mapState } from 'vuex';
import AppLayout from '@/layouts/AppLayout';
import SubNav from '@/components/SubNav';
import ProjectSubNav from './components/ProjectSubNav';
export default {
  name: 'projects-home',
  components: { AppLayout, SubNav, ProjectSubNav },
  computed: {
    ...mapState(['tenant', 'project']),
  },

  /**
   * LIFECYCLE HOOKS
   */
  beforeRouteEnter(to, from, next) {
    getPageData(to, next);
  },
  beforeRouteUpdate(to, from, next) {
    getPageData(to, next);
  },
};

async function getPageData(to, next) {
  await store.dispatch('tenant/fetchTenant', to.params.id);
  await store.dispatch('project/fetchProjectById', to.params.project_id);
  //await store.dispatch('camera/fetchCameras', to.params.project_id);
  next();
}
</script>
