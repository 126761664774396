<template>
  <div class="bg-white border-t mt-2">
    <div class="max-w-screen-xl mx-auto pt-2">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubNav',
};
</script>
